import { Lz } from '2a';
import { ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { blue } from '@material-ui/core/colors';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import Select from '@material-ui/core/Select';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

const theme = createMuiTheme({
    palette: {
        primary: blue,
        type: 'dark'
    }
});

enum EnvironmentEnum {
    production = 'production',
    sandbox = 'sandbox'
}

const environmentKey = '/slinq/settings/environment';
const versionKey = '/slinq/settings/version';

const environmentValue = window.localStorage.getItem(environmentKey);
const validEnvironment = environmentValue === 'production' || environmentValue === 'sandbox';
const defaultEnvironment = validEnvironment ? environmentValue as EnvironmentEnum : EnvironmentEnum.production;
const defaultVersion = window.localStorage.getItem(versionKey) ?? '47.0';

interface ILoginFormState {
    environment: 'production' | 'sandbox';
    version: string;
    tosAgreed: boolean;
    environmentLabelWidth: number;
    versionLabelWidth: number;
}

class Login extends React.Component<{}, ILoginFormState> {
    public state: ILoginFormState = {
        environment: defaultEnvironment,
        version: defaultVersion,
        tosAgreed: false,
        environmentLabelWidth: 0,
        versionLabelWidth: 0
    };

    private environmentLabelRef = React.createRef<HTMLLabelElement>();
    private versionLabelRef = React.createRef<HTMLLabelElement>();

    public componentDidMount(): void {
        const environmentLabelWidth = this.environmentLabelRef.current?.offsetWidth;
        const versionLabelWidth = this.versionLabelRef.current?.offsetWidth;
        this.setState({ environmentLabelWidth, versionLabelWidth });
    }

    private onEnvironmentChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ environment: e.target.value as EnvironmentEnum });
    };

    private onVersionChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.setState({ version: e.target.value });
    };

    private onTosAgreedChange = (e: React.ChangeEvent<HTMLInputElement>, tosAgreed: boolean) => {
        this.setState({ tosAgreed });
    };

    private onLoginClick = () => {
        const { environment, version } = this.state;
        window.localStorage.setItem(environmentKey, environment);
        window.localStorage.setItem(versionKey, version);
        window.location.href = `/login/${environment}`;
    };

    public render(): React.ReactNode {
        const { environment, version, tosAgreed, environmentLabelWidth, versionLabelWidth } = this.state;

        const max = 47;
        const min = 12;
        const versions = Lz.range(min, max - min + 1).select(n => max + min - n).select(n => `${n}.0`)
            .select(n => <MenuItem key={n} value={n}>{n}</MenuItem>)
            .toArray();

        return (
            <ThemeProvider theme={theme}>
                <FormControl variant="outlined" fullWidth style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="environment" ref={this.environmentLabelRef}>Environment</InputLabel>
                    <Select value={environment} onChange={this.onEnvironmentChange} input={<OutlinedInput labelWidth={environmentLabelWidth} name="environment" id="environment" />}>
                        <MenuItem value="production">Production</MenuItem>
                        <MenuItem value="sandbox">Sandbox</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="outlined" fullWidth style={{ marginBottom: 10 }}>
                    <InputLabel htmlFor="version" ref={this.versionLabelRef}>Version</InputLabel>
                    <Select value={version} onChange={this.onVersionChange} input={<OutlinedInput labelWidth={versionLabelWidth} name="version" id="version" />}>
                        {versions}
                    </Select>
                </FormControl>
                <FormControlLabel style={{ marginBottom: 10 }}
                                  control={<Checkbox checked={tosAgreed} onChange={this.onTosAgreedChange} color="primary" />}
                                  label="I agree to the terms of service" />
                <Button fullWidth variant="contained" onClick={this.onLoginClick} disabled={!tosAgreed}>Login</Button>
            </ThemeProvider>
        );
    }
}

window.addEventListener('load', () => {
    ReactDOM.render(<Login />, window.document.querySelector('main'));
});
